
a {
    text-decoration: none;
}

#chat3 .form-control {
    border-color: transparent;
    }
    
#chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
    }
    
.badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -.75rem;
    }
.active2 {
    background-color: aliceblue;
}    
.chat_window {
    border-radius: 15px;
}
    
.chat_window_left {
    border-radius: 15px 0 0 15px;
}
.card-body12 {
    padding: 0px 10px;
}
.avatar_header_main img{
    border-radius: 50%;
    height: 60px;
    width: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.avatar_header_main h5{
    font-size: 16px;
    padding-top: 8px;
    margin: 0;
}
.avatar_header_main p{
    font-size: 12px;
    padding-top: 2px;
    margin: 0;
}
.avatar_header img{
    border-radius: 50%;
    height: 45px;
    width: 45px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.avatar_header h5{
    font-size: 16px;
    padding-top: 8px;
    margin: 0;
}
.avatar_header p{
    font-size: 12px;
    padding-top: 2px;
    margin: 0;
}
.footer_chat p{
    font-size: 12px;
    padding-top: 2px;
    margin: 0;
}
.avatar{
    display: inline-block;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  object-fit: cover;
}
.avatar2{
    display: inline-block;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  object-fit: cover;
}
.chat_list_a {
    background-color: #f5f6f7;
}
.chat_list {
    position: relative; height: 400px
}
.send_msg img{
    max-height: 50px;
}
.msg_right, .msg_left {
    font-size: 10px;
}
.chat_msg {
    width: 90%;
}
.w-90 {
    width: 90%;
}
.w-75 {
    width: 75%;
}
.head_icon {
    font-size: 20px;
}
.msgbody_dark {
    background: linear-gradient(131deg, #106df6 0%, #8E54E9 100%);
    border-radius: 50px 0px 50px 50px;
    padding: 15px 15px 15px 40px !important;
    border: 1px solid #8855e9c9;
}

.msgbody_light {
    background: linear-gradient(131deg, #E2E2E2 0%, #C9D6FF 100%);
    border-radius: 0px 50px 50px 50px;
    padding: 15px 15px 15px 40px !important;
    border: 1px solid #e0e1e5;
}

@media screen and (min-width: 400px) {
    body {
        display: inherit;
    }
  }  